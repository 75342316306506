// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: "v726demo1",
  USERDATA_KEY: "authf649fc9a5f55",
  isMockEnabled: false,
  keyCloackUrl: "https://hmlg-accounts.fraga.com.br",
  apiUrl: "https://hmlg-api.space.fraga.com.br",
  intelliautoUrl: "https://hmlg.intelliauto.com.br",
  masterDeProdutosUrl: "https://hmlg-masterdeprodutos.fraga.com.br",
  frotaCirculanteUrl: "https://hmlg-admin.frota.fraga.com.br",
  frotaCirculanteFrontUrl: "https://hmlg-frota.fraga.com.br",
  potencialMercadoFrontUrl: "https://hmlg-potencial.fraga.com.br",
  whatsappSuporte: "https://wa.me/5519971181895",
  realm: "spacef",
  clientId: "spacef-admin",
  localApi: "https://localhost:5021",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

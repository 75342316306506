import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthGuard extends KeycloakAuthGuard {
  currentUserSubject: BehaviorSubject<KeycloakProfile>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): KeycloakProfile {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: KeycloakProfile) {
    this.currentUserSubject.next(user);
  }

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
    this.currentUserSubject = new BehaviorSubject<KeycloakProfile>(undefined);
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.isLoadingSubject.next(true);

    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    this.currentUserSubject = new BehaviorSubject<KeycloakProfile>(
      await this.keycloak.loadUserProfile()
    );

    this.isLoadingSubject.next(false);

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
